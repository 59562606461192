// import React, { useRef, useEffect } from 'react';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Image from '@components/Image';
import FlourishHeader from '@components/FlourishHeader';
import { trackClickEvent } from '@utils/analytics';
import { buildContentfulImageURL } from '@utils/image';
import useVerticalIndex from '@/src/hooks/useVerticalIndex';
import CarouselArrow from '@/src/components/CarouselArrow';
import AnchorLink from '@/src/components/AnchorLink';
import s from './FlexibleCarousel.module.scss';

const propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  addTextBackground: PropTypes.bool,
  contentSlots: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
      mainImage: PropTypes.shape({
        image: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
      contentfulId: PropTypes.string,
    }),
  ),
  rowCount: PropTypes.number,
};

const FlexibleCarousel = ({
  header = '',
  subheader = '',
  contentSlots = [],
  addTextBackground = false,
  rowCount = 1,
}) => {
  const { verticalIndex, componentRef } = useVerticalIndex();
  const slickRef = useRef(null);

  const sliderSettings = {
    ref: slickRef,
    speed: 400,
    dots: false,
    initialSlide: 2,
    slidesToScroll: 0.9,
    centerMode: true,
    infinite: true,
    prevArrow: <CarouselArrow type="prev" containerClass={s.prevArrow} />,
    nextArrow: <CarouselArrow type="next" containerClass={s.nextArrow} />,
    responsive: [
      {
        breakpoint: 10000, // This is a hack to handle widths > 768.
        settings: {
          slidesToShow: 4,
          centerPadding: '80px',
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
          centerPadding: '40px',
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          centerPadding: '22.5%',
        },
      },
    ],
  };

  return (
    <div className={[s.root, s[`row${rowCount}`]].join(' ')} ref={componentRef}>
      {header && <FlourishHeader headerText={header} subText={subheader} />}
      <div className={s.sliderContainer}>
        <Slider {...sliderSettings}>
          {contentSlots.map((sliderItem, index) => {
            const imageSet = [
              {
                src: `${buildContentfulImageURL(sliderItem?.mainImage?.image?.file?.url, {
                  width: 400,
                }) ?? null}`,
              },
            ];

            return (
              <div
                className={[s.sliderItem, addTextBackground ? s.addTextBackground : ''].join(' ')}
                key={sliderItem.contentfulId}
              >
                <AnchorLink
                  href={sliderItem.url}
                  onClick={() => {
                    trackClickEvent(
                      {
                        destination_url: sliderItem.url,
                        label: sliderItem.label,
                        location: 'Flexible Carousel',
                        vertical_index: verticalIndex,
                        horizontal_index: index,
                        content_name:
                          sliderItem.label || /* istanbul ignore next */ sliderItem.entryTitle,
                      },
                      sliderItem,
                    );
                  }}
                >
                  <Image imageSet={imageSet} />

                  <div className={s.text}>
                    <div className={s.textBorder}>
                      <span>{sliderItem.label}</span>
                    </div>
                  </div>
                </AnchorLink>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

FlexibleCarousel.propTypes = propTypes;
export default FlexibleCarousel;
