/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import s from './StyledHeading.module.scss';

const StyledHeading = ({ text }) => {
  return text.split(' ').map((word, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <span key={`${word}-${index}`} className={s.word}>
      <span>{word.charAt(0)}</span>
      <span>{word.slice(1)}</span>{' '}
    </span>
  ));
};

export default StyledHeading;
